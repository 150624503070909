import React from 'react'
import PageWrapper from '../components/pagewrapper'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Footer from '../components/footer'
import {Helmet} from 'react-helmet'
import bg1 from '../../static/bg-1.jpg';




class Mainabout extends React.Component{
  constructor(props) {
    super(props)
  }

  render() {
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen.links
    }
    else {
      menu = this.props.pageContext.menuel.links
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({path:item.entityUrl.path, id:item.entityLanguage.id.toUpperCase()})
    })
    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
  var breadpath = this.props.location.pathname.split(/[/]/)
  breadpath.shift()

  breadpath.forEach((item,i) => {
    var value = null;
    var path = null;
    // console.log(item,i,loc[0])

    if(i === 0) {
      if(item === 'en' || item === 'el') {
        value = 'HOME';
        path=`/${item}`
      }
      else {
        value=item;
        path=`/${item}`
      }
      loc.push({value: value, path: path})
    }
    else {
      value=item;
      path=`${loc[i-1].path}/${item}`
      loc.push({value: value, path: path})
    }





  })


       return (
         <div>
           <Helmet>
             {this.props.pageContext.metainfo.map((item,i) => {
       if(item.key === 'title') {
         return (
           <title key={i}>{item.value}</title>
         )
       }
       else if(item.key === 'canonical') {
         return (
           <link key={i} rel="canonical" href={this.props.path} />
         )
       }

       else if(item.key === 'description') {
         return (
           <meta key={i} name="description" content={item.value}/>
         )
       }

       else {
         return null;
       }
     })}
           </Helmet>
          <PageWrapper>
        <Header info={this.props.data.drupal.contactInfo} logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu} langcontainer={langpath} path={this.props.path}/>
          <Breadcrumb background={this.props.pageContext.background} title={this.props.pageContext.title} path={loc}/>
            <div className="section-full p-tb80 bg-white inner-page-padding">
               <div className="container">

         <div className="section-head">
           <div className="mt-separator-outer separator-left">
             <div className="mt-separator">
               {this.props.pageContext.langcode === 'EN'? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Company</h2>
             : <h2 className="text-uppercase sep-line-one ">Η <span className="font-weight-300 text-primary">εταιρεια</span> μας </h2>}

             </div>
           </div>
         </div>

                   <div className="section-content ">
                     <div className="row">
                         <div className="col-md-5 col-sm-6">
                             <div className="m-about ">
                               <div className="owl-carousel about-us-carousel owl-loaded owl-drag">
                                 <div className="owl-stage-outer">
                                   <div className="owl-stage">
                                    <div className="carouselcontainer2">
                                      <div className="owl-item">
                                        <div className="item">
                                      <Carousel infiniteLoop={true} interval={4000} showArrows={false} dynamicHeight={true} transitionTime={300} showStatus={false} autoPlay={true} showThumbs={false}>

                                            {this.props.data.drupal.nodeQuery.entities[0].fieldAbouticon.map((item,i)=> {
                                              return (
                                                <div key={i} className="owl-img">
                                                <img className="croped2" style={{maxHeight:'250px'}} src={item.entity.thumbnail.imageFile.childImageSharp.fixed.src} alt={item.entity.thumbnail.alt} />
                                                   </div>
                                              )
                                            })}








                                      </Carousel>
                                      </div>
                                         </div>
                                    </div>

                                    </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                       <div className="col-md-7 col-sm-6">
                             <div className="m-about-containt text-black p-t80">
                                 <div className="m-about-years bg-moving">
                                       <span className="text-primary large-title">20</span>
                                       <span className="large-title-info">{this.props.pageContext.langcode === 'EN'? 'Years of Experience': 'χρονια εμπειριας'}</span>
                                   </div>

                                    <div dangerouslySetInnerHTML={{__html: this.props.data.drupal.nodeQuery.entities[0].body.processed}}></div>
                                   <div className="author-info p-t20">




                                       <Link className="site-button btn-effect m-b15" to={`/${this.props.pageContext.langcode.toLowerCase()}/contact`}>
                                        <span>{this.props.pageContext.langcode==='EN'? 'Get in touch' : 'Επικοινωνια'}</span>
                                       </Link>


                                   </div>



                               </div>
                           </div>

                       </div>
                   </div>
               </div>
           </div>

           {/*Section Contact  */}
          <div className="section-full p-tb80 overlay-wraper bg-center bg-parallax bg-cover" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${bg1})`, backgroundPosition: '50% -12.5834px'}}>
           <div className="overlay-main bg-primary opacity-07"></div>
              <div className="container">

         <div className="section-content">
           <div className="call-us-section text-center">
                              <h4 className="m-b15">{this.props.pageContext.langcode === 'EN'? 'For more informations' : 'Για περισσότερες πληροφορίες'}</h4>
                              <h2 className="call-us-number m-b15 m-b0">{this.props.data.drupal.contactInfo.fieldPhone}</h2>
                              <h4 className="call-us-address m-t0 m-b20">{this.props.data.drupal.contactInfo.fieldAddress}</h4>
                              <Link className="site-button-secondry btn-effect bg-dark" to={this.props.pageContext.langcode === 'EN'? '/en/contact': '/el/contact'}>
                               {this.props.pageContext.langcode === 'EN'? 'Contact us': 'Επικοινωνια'}
                              </Link>

           </div>
         </div>

              </div>
          </div>


           <Footer social={this.props.data.drupal.social} logourl={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu}  info={this.props.data.drupal.contactInfo}/>

         </PageWrapper>
        </div>
       )
  }






}

export default Mainabout;


export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
    footerLogo:nodeById(id: "25") {
     ... on DRUPAL_NodeLogo {

       fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
     }
   }
    headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
   }
 }
    contactInfo: nodeById(id: "26", language: $langcode) {
   title
   ... on DRUPAL_NodeContactInfo {
     fieldAddress
     fieldEmail
     fieldPhone
   }
 }
 social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
    entities {
      ... on DRUPAL_NodeSocialLinks {

        fieldIcon
        fieldLink
      }
    }
  }
nodeQuery(filter: {conditions: [{field: "type", value: "about"}]}) {
 entities(language: $langcode) {
   ... on DRUPAL_NodeAbout {
       entityLabel
       body {
          processed
        }
        fieldAbouticon {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                 childImageSharp {
                   fixed(width: 700, height: 500, fit: COVER, quality: 90) {
                     src
                   }
                 }
               }
              derivative(style: XL900X700) {
                url
              }
            }
          }
        }
     }
 }
}
}
}
`
